/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@use "node_modules/@ngxpert/hot-toast/src/styles/styles.scss";
@import "@ionic/angular/css/core.css";
@import "prismjs/themes/prism-okaidia.css";
@import "prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "prismjs/plugins/line-highlight/prism-line-highlight.css";
/* Basic CSS for apps built with Ionic */

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,600;0,700;1,400;1,900&display=swap");

/* Add application styles & imports to this file! */
@import "swiper/css/autoplay";
// regular style toast
@import "ngx-toastr/toastr";
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Raleway";
}

// .bg-primary {
//   background-color: #232c41;
// }
// .bg-quiz {
//   background-color: #4c5874;
// }
// .bg-white {
//   background-color: white;
// }
.bg-primary {
  background-color: #4c5874;
}
.bg-quiz {
  background-color: #4c5874;
}
.bg-white {
  background-color: white;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}


.dialog-header {
  flex: 0 0 auto; /* Prevents the header from shrinking */
}

.dialog-content {
  flex: 1 1 auto; /* Allows the content area to grow and shrink */
  overflow-y: auto; /* Enables scrolling for the content area */
}
.shadow-complex {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25),
    inset 7px 7px 14px 0 rgba(0, 0, 0, 0.25),
    inset -7px -7px 14px 0 rgba(255, 255, 255, 0.25);
}
.button1 {
  animation: float 2s infinite ease-in-out;
}
@font-face {
  font-family: "Rage Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Rage Italic"), url("assets/fonts/RAGE_1.TTF") format("truetype");
}
body {
  background: #232c41;
  overflow: hidden;
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by modern browsers */
}
.custom-color-primary .mat-checkbox-frame {
  border-color: wheat;
}
.mat-checkbox .mat-checkbox-frame {
  border-color: wheat !important;
}
.mat-drawer {
  width: 75%;
}
:root {
  --mdc-checkbox-unselected-icon-color: white;
  --mdc-checkbox-unselected-hover-icon-color: white;
  --mat-bottom-sheet-container-background-color: #232c41;
  --mdc-dialog-container-shape: 18px;
  --mdc-dialog-container-color: #232c41;
  --mdc-dialog-supporting-text-color: white;
  --mdc-checkbox-unselected-focus-icon-color: white;
  --mdc-filled-text-field-container-color: #495674;
  --mat-select-enabled-trigger-text-color: white;
  --mdc-filled-text-field-label-text-color: white;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: white;
  --mat-select-enabled-arrow-color: white;
  --mat-select-focused-arrow-color: white;
  --mat-select-panel-background-color: #495674;
  --mat-option-label-text-color: white;
  --mat-option-selected-state-label-text-color: white;
  --mat-option-selected-state-layer-color: #fe565e;
  --mdc-filled-text-field-focus-label-text-color: white;
  --mat-paginator-container-background-color: #232c41;
  --mat-paginator-disabled-icon-color: rgba(160, 157, 157, 0.4);
  --mat-paginator-enabled-icon-color: #fe565e;
  --swiper-navigation-color: #fe565e;
  --mat-menu-container-color: #495674;
  --mat-switch-label-text-color: white;
}
.typed-out {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 10s forwards;
  width: 0;
}
@media (max-width: 100vw) {
  .cdk-overlay-pane.update-dialog.mat-mdc-dialog-panel {
    max-width: 100vw;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 400px;
    height: 50%;
  }
}
.input-bg {
  background: linear-gradient(#495674 0%, #3d4558 95.3%);
  border-radius: 10px;
}

.chat-bg {
  background: linear-gradient(#353739 0%, #677494 95.3%);
  border-radius: 10px;
}
.chat-input-bg {
  background: linear-gradient(180deg, #211f1f 0%, rgba(51, 51, 51, 0.8) 95.3%);
  border-radius: 10px;
}

/* Base styles for Markdown content */
.markdown-body {
  line-height: 1.6;
  font-weight: 600;
}

/* Heading styles */
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 800;
}

.markdown-body h1 {
  font-size: 1.8em; /* 32px */
}

.markdown-body h2 {
  font-size: 1.4em; /* 24px */
  border-bottom: 1px solid #eaecef; /* Optional: add a line below h2 */
}

.markdown-body h3 {
  font-size: 1.25em; /* 20px */
}

.markdown-body h4 {
  font-size: 1em; /* 16px */
}

.markdown-body h5 {
  font-size: 0.875em; /* 14px */
}
.markdown-body li {
  margin-top: 12px;
  margin-bottom: 12px;
}
.markdown-body h6 {
  font-size: 0.85em; /* 13.6px */
}

.markdown-body p {
  margin-top: 12px;
  margin-bottom: 12px;
}

/* Blockquote styles */
.markdown-body blockquote {
  padding-left: 20px;
  margin-left: 0;
  margin-right: 0;
  border-left: 4px solid #ccc; /* Color can be adjusted */
  color: #6a737d; /* A lighter shade for quotes */
  font-style: italic;
}

/* List styles */
.markdown-body ul,
.markdown-body ol {
  list-style-type: disc;
  padding-left: 20px;
}

/* Link styles */
.markdown-body a {
  color: #fe565e;
  text-decoration: underline;
}

/* Code block styles */
.markdown-body pre,
.markdown-body code {
  font-family: monospace;
  background-color: #0d0d0d;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  color: white;
}

.markdown-body pre {
  padding: 16px;
  overflow: auto;
  line-height: 1.45;
  border-radius: 3px;
}

img {
  -webikit-user-drag: none;
  -webkit-touch-callout: none;
}

.mat-horizontal-stepper-header {
  padding-left: 15px;
  padding-right: 15px; /* Example override */
}
@media (max-width: 768px) {
  .scroll-container {
    height: calc(100vh - 56px); /* Adjust height dynamically */
    overflow-y: auto;
  }
}
@supports (padding: env(safe-area-inset-bottom)) {
  .safe-area {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
.mat-bottom-sheet-container {
  max-height: 95vh !important;
  overflow-y: auto !important;
}
/* Override default Shepherd button styles */
.shepherd-button {
  background-color: #007bff; /* Custom background color */
  color: #fff; /* Custom text color */
  border-radius: 4px; /* Custom border radius */
  padding: 8px 16px; /* Custom padding */
  font-size: 14px; /* Custom font size */
  border: none; /* Remove default border */
}

/* Override primary button styles */
.shepherd-button-primary {
  background-color: #28a745; /* Custom background color for primary buttons */
  color: #fff; /* Custom text color */
}

/* Override secondary button styles */
.shepherd-button-secondary {
  background-color: #6c757d; /* Custom background color for secondary buttons */
  color: #fff; /* Custom text color */
}

/* Add additional hover effects if needed */
.shepherd-button:hover {
  background-color: #0056b3; /* Custom hover background color */
  color: #e9ecef; /* Custom hover text color */
}
.tour-step {
  .mat-mdc-card-title {
    color: #3f51b5;
    font-size: 18px;
    font-weight: bold;
  }

  .mat-mdc-card-content {
    color: #333;
    font-size: 15px;
    line-height: 1.5;
  }

  .mat-mdc-card {
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}
mat-card-content{
  color: #333;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
}
/* Default style for images */
// .markdown-body img {
//   max-width: 100%;
//   transition: transform 0.3s ease;
//   cursor: zoom-in;
// }

/* Fullscreen zoom effect */
// .zoomed {
//   transform: scale(2);
//   transition: transform 0.3s ease;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%) scale(2);
//   z-index: 1000;
//   background: rgba(0, 0, 0, 0.5);
//   padding: 10px;
//   border-radius: 10px;
// }

// /* Background overlay */
// .zoom-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   z-index: 999;
// }
/* styles.css */
// .markdown-body img {
//   transition: transform 0.2s; /* Smooth transition */
//   cursor: pointer; /* Indicate that the image is interactive */
// }

// .markdown-body img:hover {
//   transform: scale(1.5); /* Zoom in by 50% */
// }

/* Ensure images in markdown are clickable */
.markdown-body img {
  cursor: pointer;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

/* Fullscreen modal for zoomed image */
.zoom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Zoomed image styling */
.zoomed-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

/* Angular Material Dialog and BottomSheet overrides */
.mat-bottom-sheet-container {
  padding: 0 !important;
  background-color: transparent !important;
  overflow: visible !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  max-height: 85vh !important; /* Allow it to take most of the screen height */
  height: auto !important; /* Let the height be determined by content */
}

.cdk-overlay-pane.mat-bottom-sheet-container {
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 !important;
}

.explanation-dialog-panel {
  max-width: 100vw !important;
  width: 100% !important;
}

.explanation-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Animation for dialog sliding up */
@keyframes slideUpDialog {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Explanation dialog container animation */
.explanation-dialog-container {
  animation: slideUpDialog 0.3s ease-out forwards;
}

/* Prevent body scrolling when bottom sheet is open */
body.mat-bottom-sheet-open {
  overflow: hidden !important;
}

/* Custom scrollbar */
.dialog-content::-webkit-scrollbar {
  width: 6px;
}

.dialog-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.dialog-content::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Loading animation dots */
.loading-text span::after {
  content: '';
  animation: dots 1.5s infinite;
}

@keyframes dots {
  0%, 20% { content: ''; }
  40% { content: '.'; }
  60% { content: '..'; }
  80%, 100% { content: '...'; }
}
